import { ColumnBase, DataListColumn, DataListLookupColumn, IDataListColumn } from '../utils';
import { AnyMember, Boolean, CubesDinero, DataQueryGraph, dateCubes, DateExt, field, FieldClass, FieldNames, Float, is, ok, Prisma, PrismaQuery, ScalarDate, schema, SRResult, String, TABLE_NAMES, TableViewColumn, truthy } from "common";
import * as PrismaExtra from "prisma-client";
import { DataService } from "data-service";
import { format } from "date-fns";
import { TableColumnViewOptions, TableViewClass, assignColumnOptions } from './table-views';
import { SRMap } from "common";
import { StatusBadge } from 'react-utils';
import React from 'react';
let fieldindex = 0;

type AutopayStatus = Awaited<SRResult<"queryCustomerAutopayStatus_new">>[number]

// export class MemberClass extends FieldClass { 
//   constructor(key: string, item: AnyMember<any, any>) { 
//     super(key, fieldindex++, item, false, false); } }

export class MemberClass extends FieldClass {
  constructor(key: string, item: AnyMember<any, any>) {
    const type: any = item.__type;
    const name: FieldNames = item.__name as any;
  
    if (!(["scalar", "enum", "record", "table"] as const).includes(type))
      throw new Error("Invalid type: " + item.__type);
    super({
      key, order: fieldindex++, type, name,
      isRequired: false, isArray: false,
      attributes: {}, 
      fieldOptions: Object.fromEntries(item.__listOptions())
    }, schema);
  }
}


export class CustomColumnDef<V> {
  static getColumnClass(key: string, filterType: "boolean" | "currency" | "text" | "numeric" | "date" | "enum" | "none") {

    const filterType2 =
      filterType === "currency" ? "numeric" :
        filterType === "none" ? undefined :
          filterType;

    const fieldAttr = new field({ filterType: filterType2 });
    switch (filterType) {
      case "boolean": return new MemberClass(key, new Boolean(false, fieldAttr));
      case "currency": return new MemberClass(key, new CubesDinero(false, fieldAttr));
      case "text": return new MemberClass(key, new String(false, fieldAttr));
      case "numeric": return new MemberClass(key, new Float(false, fieldAttr));
      case "date": return new MemberClass(key, new ScalarDate("yyyy-MM-dd", false, fieldAttr));
      case "enum": return new MemberClass(key, new String(false, fieldAttr));
      case "none": return new MemberClass(key, new String(false, fieldAttr));
      default: ok(false, "Invalid filterType: " + filterType);
    }

  }
  map: Map<string, V>;
  col: DataListLookupColumn<V>;
  constructor(
    key: string,
    title: string,
    filterType: "boolean" | "currency" | "text" | "numeric" | "date" | "enum" | "none",
    options: TableColumnViewOptions = {},
    markup?: (value: V) => React.JSX.Element | null
  ) {


    this.col = new DataListLookupColumn(key, 0, title, CustomColumnDef.getColumnClass(key, filterType));
    if (markup) this.col.markup = markup as any;
    this.map = this.col.lookup;
    assignColumnOptions(this.col, options);
  }
}

export class CustomColumnState {
  constructor(
    private table: TABLE_NAMES,
    private data: DataService,
    private cols: ColumnBase[]
  ) {
    this.autopayColumns.forEach(([key, title, filterType, fn]) => {
      this[key] = new CustomColumnDef<any>(
        key,
        title,
        filterType,
        { displayGroup: "Autopay" },
      );
    });

    if (this.table === "Customer") {
      this.register(this.customerReadyForAutopay);
      this.register(this.hostBalanceLookup);
      this.register(this.customerPastDueBalanceLookup);
      this.register(this.customerPastDueSinceLookup);
    }

    if (this.data.userRole === "web_admin" && ["Branch", "Owner", "Division"].contains(table)) {
      this.register(this.hostBalanceLookup);
      if (table === "Branch") {
        this.register(this.salesTaxLookup);
      }
    }

    if (this.table === "CustomerLedger") {

      if (this.data.userRole === "web_admin") {
        this.autopayColumns.forEach(([key, title, filterType, fn]) => {
          this.register(this[key]);
        });
      } else {
        this.register(this.willAutopay);
      }
    }

  }

  register(col: CustomColumnDef<any>) {
    this.cols.push(col.col);
  }

  canceled: boolean;

  onLoadHook(query: DataQueryGraph, AND: any[]) {
    query.addExtraRequest(() => {
      const proms = [];

      if (this.table === "Customer") {
        proms.push(this.clientCustomerBalance(AND));
      }

      if (this.data.userRole === "web_admin") {
        if (this.table === "Branch") {
          proms.push(this.clientBranchBalance(AND));
        } else if (this.table === "Owner") {
          proms.push(this.clientOwnerBalance(AND));
        } else if (this.table === "Division") {
          proms.push(this.clientDivisionBalance(AND));
        }
      }

      if (is<Prisma.CustomerLedgerWhereInput[]>(AND, this.table === "CustomerLedger")) {
        proms.push(this.clientAutopayLoad(AND));
      }

      return Promise.all(proms);

    });

  }

  onValueFilter(value: any[]): any[] {

    if (this.table === "Customer") {
      // the balance only includes non-testing lines, 
      // so test customers are confusing because they would show as zero.
      value.forEach(e => {
        if (e.id && e.IS_TESTING === true)
          this.hostBalanceLookup.map.delete(e.id);
      });
    }

    return value;

  }


  setLookup<L extends CustomColumnDef<any>, V extends { id: string }>(lookup: L, value: V[], map: (e: V) => any) {
    lookup.map.clear();
    value.forEach(e => lookup.map.set(e.id, map(e)));
  }


  async clientBranchBalance(AND: any[]) {
    const { branches, salesTax } = await this.data.server.queryBranchBalance({ AND });
    if (this.canceled) return;
    this.hostBalanceLookup.map.clear();
    branches.forEach(([branchID, balance]) => this.hostBalanceLookup.map.set(branchID, balance));
    this.salesTaxLookup.map.clear();
    salesTax.forEach(([branchID, balance]) => this.salesTaxLookup.map.set(branchID, balance));
  }

  async clientOwnerBalance(AND: any[]) {
    const mapBalance = await this.data.server.queryOwnerBalance({ AND });
    if (this.canceled) return;
    this.hostBalanceLookup.map.clear();
    mapBalance.forEach(([ownerID, balance]) => this.hostBalanceLookup.map.set(ownerID, balance));
  }
  async clientDivisionBalance(AND: any[]) {
    const mapBalance = await this.data.server.queryDivisionBalance({ AND });
    if (this.canceled) return;
    this.hostBalanceLookup.map.clear();
    mapBalance.forEach(([divisionID, balance]) => this.hostBalanceLookup.map.set(divisionID, balance));
  }

  salesTaxLookup = new CustomColumnDef<number>("salesTaxLookup", "Sales Tax Balance", "currency", { displayGroup: "Balance" });
  hostBalanceLookup = new CustomColumnDef<number>("balanceLookup", "Balance", "currency", { displayGroup: "Balance" });

  customerPastDueBalanceLookup = new CustomColumnDef<number>("pastDueBalanceLookup", "Past Due", "currency", { displayGroup: "Balance" }, (value) => {
    return <StatusBadge
      value={value <= 0}
      trueLabel={DataListColumn.textCubesDinero(value)}
      trueTone='success'
      falseLabel={DataListColumn.textCubesDinero(value)}
      falseTone='warning'
      size="medium"
    />;
  });
  customerAutopayDataLookup = new CustomColumnDef<AutopayStatus>("customerAutopayDataLookup", "Autopay Data", "none", { displayGroup: "Autopay" },);
  customerPastDueSinceLookup = new CustomColumnDef<string>("pastDueSince", "Past Due Since", "date", { displayGroup: "Balance" });
  customerReadyForAutopay = new CustomColumnDef<boolean>("customerReadyForAutopay", "Ready for Autopay", "boolean", { displayGroup: "Autopay" });

  async clientCustomerBalance(AND: Prisma.CustomerWhereInput[]) {
    const res3 = await this.data.server.queryCustomerAutopayStatus_new({ where: { AND }, includeOld: false });
    const res4 = await this.queryCustomerOldestUnpaidInvoice({ AND });
    if (this.canceled) return;
    this.setLookup(this.customerReadyForAutopay, res3, e => e.customerReady);
    this.setLookup(this.hostBalanceLookup, res3, e => e.Balance ?? 0);
    this.setLookup(this.customerPastDueBalanceLookup, res3, e => Math.max(0, e.BalancePastDue ?? 0));
    this.setLookup(this.customerAutopayDataLookup, res3, e => e);

    this.setLookup(this.customerPastDueSinceLookup, res4, e => e.LedgerLines[0]?.line.Date);
  }

  async queryCustomerOldestUnpaidInvoice(where: Prisma.CustomerWhereInput) {
    return await this.data.prisma.customer.findMany({
      where,
      select: {
        id: true,
        AutoPay: true,
        LedgerLines: {
          select: { line: { select: { Date: true } } },
          where: { line: { VoidSince: null, invoiceLine: { paidOn: null } } },
          orderBy: { line: { Date: "asc" } },
          take: 1,
        },
      }
    });
  }


  willAutopay: CustomColumnDef<any>

  autopayColumns = [
    ["willAutopay", "Will Autopay", "boolean", e => e.isLineAlreadyPaid ? undefined : !!e.willAutopay],
  ] as const satisfies [string, string, string, (e: CustomColumnState["autopayLine"]) => any][];

  declare autopayLine: Awaited<ReturnType<SRMap["queryGetAutopayStatusForCustomerLedger_new"]>>[number];

  async clientAutopayLoad(AND: Prisma.CustomerLedgerWhereInput[] = []) {

    const autopay = await this.data.server.queryGetAutopayStatusForCustomerLedger_new({ where: { AND } });
    if (this.canceled) return;
    this.autopayColumns.forEach(([key, title, filterType, fn]) => { this[key].map.clear(); });
    autopay.forEach(e => {
      this.autopayColumns.forEach(([key, title, filterType, fn]) => { this[key].map.set(e.id, fn(e)); });
    });
  }

}
