import { is, jsonify, Modes, ok, Prisma, TABLE_NAMES, truthy } from "common";
import { DataPage } from "./question-dialog";
import { QuestionGroup, QuestionInputNumber, QuestionRender, QuestionSelect, QuestionSimple } from "./question-base";
import { Badge, BlockStack, BlockStackProps, Box, BoxProps, Button, ButtonGroup, DataTable, Divider, ExceptionList, Icon, InlineError, InlineStack, Loading, ModalProps, Spinner, Text, TextProps } from "@shopify/polaris/index";
import { PropsWithChildren, ReactNode, useCallback, useContext, useLayoutEffect, useMemo, useRef, useState, useSyncExternalStore } from "react";
import { ButtonAwait, ConfirmationService, emitGlobalRefresh, EventEmitter, globalConfirmation, globalMessage, Injector, ModalHostEvents, StatusBadge, useAngular, useAsyncEffect, useObservable, useObserver, useRefresh } from "react-utils";
import { AlertCircleIcon, CheckCircleIcon, InfoIcon } from "@shopify/polaris-icons";
import { UIService } from "./ui.service";
import { DataService } from "data-service";
import { DataListColumn } from "./question-classes";
import { DateTime } from "luxon";
import { dollarsToCents } from "../pages/page-table-list";
import { showPageEditModal } from "./showPageEditModal";
import { renderGroupControls } from "../components/QuestionForm";
import { from, Observable, of, startWith } from "rxjs";
import * as PrismaExtra from "prisma-client";
import { branchesQuery, BranchSelectorContext, CurBranch, useBranchSelector } from "./useBranchSelector";
/** shenanigans: this changes the type into what we would see in client */
function jsonify<T>(obj: T): jsonify<T> {
  return obj as any;
}
const B = (props: Omit<TextProps, "as" | "fontWeight">) => <Text {...props} as="span" fontWeight='bold' />;

export async function showCustomerVerifyModal(customerID: string, refreshOnClose: boolean, changeEmail: boolean) {
  showPageEditModal({
    table: "Customer",
    id: customerID,
    hideSaveButton: true,
    refreshOnClose,
    group: (page, mode, injector) => {
      const BranchList = new EventEmitter<Awaited<ReturnType<typeof branchesQuery>>>();
      const ui = injector.get(UIService);
      const data = injector.get(DataService);
      let
        countValidCodes = 0,
        createAccountDisabled = true,
        storageAgreementTried = false,
        storageAgreementSent = false,
        storageAgreementError: string | undefined = undefined;
      const serverCountValidCodes = async () => {
        ({ count: countValidCodes } = (await data.server.serverCountCustomerEmailCodes({ customerID })));
      }
      const setStageEmailVerified = () => {
        group.controls.CodeValid.hidden = false;

        group.controls.Email.readonly = true;
        group.controls.Email.helptext = "";
        group.controls.EmailSent.hidden = true;
        group.controls.SendCode.hidden = true;
        group.controls.Code.hidden = true;
        group.controls.CheckCode.hidden = true;
        const AWSID = group.controls.AWSID.form.value;
        group.controls.Password.hidden = !!AWSID;
        group.controls.CreateAccount.hidden = !!AWSID;
      }
      const group = new QuestionGroup({
        __typename: "Customer",
        controls: {
          AWSID: new QuestionSimple("Hidden", {}),
          EmailVerified: new QuestionSimple("CheckBox", { hidden: true }),
          StorageAgreementStatus: new QuestionSimple("Hidden", {}),
          // Header: new QuestionRender({
          //   clientSideOnly: true,
          //   render: (): ReactNode => {
          //     return !countValidCodes ? <>
          //       <p>Use this form to send a code to the customer's email address.</p>
          //     </> : !group.form.value.EmailVerified ? <>
          //       <p><B>Ask them for the code</B> to confirm their email address.</p>
          //     </> : !group.form.value.AWSID ? <>
          //       <p>Now that the email is verified, <B>create a password</B> for the
          //         customer. This will allow them to access their storage agreement.</p>
          //     </> : <>
          //       <p>Account created. <B>Send the storage agreement</B> to the customer.</p>
          //     </>;
          //   }
          // }),
          Email: new QuestionSimple("InputText", {
            title: "Use this form to send a code to the customer's email address.",
            helptext: "You can change the email address. Changes will be saved once the code is verified.",
            required: true,
            onLoadHook: (tag) => {
              // this will finish before the page data so group.form.value will be empty
              tag.addExtraRequest(serverCountValidCodes);
            },
          }),
          EmailSent: new QuestionRender({
            clientSideOnly: true,
            hidden: true,
            render: () => {
              useObservable(group.form.valueChanges);
              return <BlockStack gap="200" inlineAlign="start">
                <ExceptionList items={[{
                  icon: CheckCircleIcon,
                  title: "Code sent"
                }]} />
              </BlockStack>;
            }
          }),
          SendCode: new QuestionRender({
            clientSideOnly: true,
            render: () => {
              useObservable(group.form.valueChanges);
              return <>
                <ButtonAwait onClick={async () => {
                  const Email = group.controls.Email.form.value;
                  if (!Email) return;
                  const [good, error] = await data.server.serverSendCustomerEmailCode({ customerID, Email }).try();
                  group.controls.Email.errortext = error;
                  group.controls.EmailSent.hidden = !good;
                  if (good) {
                    group.controls.Code.hidden = false;
                    group.controls.CheckCode.hidden = false;
                  }
                  group.form.updateValueAndValidity();
                }}>Send Code</ButtonAwait>
              </>;
            }
          }),
          Code: new QuestionSimple("InputText", {
            clientSideOnly: true,
            //<p><B>Ask them for the code</B> to confirm their email address.</p>
            title: "Ask them for the code to confirm their email address",
            helptext: "If the customer recieved multiple codes, any valid code will work.",
          }),
          CheckCode: new QuestionRender({
            clientSideOnly: true,
            render: () => {
              const cleanCode = group.controls.Code.form.value?.replace(/\D/g, "") ?? "";
              return <ButtonAwait onClick={async () => {
                const Email = group.controls.Email.form.value;
                if (!Email) return;
                const Code = group.controls.Code.form.value;
                if (!Code) return;
                const [good, error, value] = await data.server.serverCheckCustomerEmailCode({
                  customerID, Email, Code: Code.replace(/\D/g, "")
                }).try();
                group.controls.Code.errortext = error;

                if (good) {
                  if (Email !== value.Email) {
                    group.controls.Email.form.setValue(value.Email);
                    group.controls.Email.errortext = "The code provided was sent to this email so that's what we used.";
                  }
                  setStageEmailVerified();
                  group.form.markAsPristine();
                }
                group.form.updateValueAndValidity();
              }}>Check Code {cleanCode}</ButtonAwait>
            }
          }),
          CodeValid: new QuestionRender({
            clientSideOnly: true,
            hidden: true,
            render: () => {
              useObservable(group.form.valueChanges);
              return <BlockStack gap="200" inlineAlign="start">
                <ExceptionList items={[
                  group.controls.EmailVerified.form.value
                    ? { icon: CheckCircleIcon, title: "Email Verified" }
                    : { icon: CheckCircleIcon, title: "Code valid" },
                  group.controls.AWSID.form.value
                    ? { icon: CheckCircleIcon, title: "Account Created" }
                    : { icon: InfoIcon, title: "Password required" },
                  storageAgreementTried ? storageAgreementSent
                    ? { icon: CheckCircleIcon, title: "Storage Agreement Sent" }
                    : {
                      icon: AlertCircleIcon, status: "warning" as const,
                      title: "Storage Agreement failed to send: " + storageAgreementError
                    }
                    : undefined,
                ].filter(truthy)} />
                {!group.controls.AWSID.form.value && <p>
                  Now that the email is verified, <B>create a password</B> for the
                  customer. This will allow them to access their storage agreement.
                </p>}
              </BlockStack>;
            }
          }),
          Password: new QuestionSimple("InputText", {
            clientSideOnly: true,
            hidden: true,
            title: "Password",
            helptext: "Password must be at least 8 characters long and contain at least one lower-case letter and one number.",
            onChange: (val) => {
              const { Password: v } = val as typeof group.form.value;
              createAccountDisabled = true;
              if (v && /[A-Za-z]/.test(v) && /[0-9]/.test(v) && v.length >= 8) {
                createAccountDisabled = false;
              }
            }
          }),
          CreateAccount: new QuestionRender({
            clientSideOnly: true,
            hidden: true,
            render: () => {
              useObservable(group.form.valueChanges);
              return <BlockStack gap="200" inlineAlign="start">
                <ButtonAwait
                  variant="primary"
                  disabled={createAccountDisabled}
                  onClick={async () => {
                    const Email = group.controls.Email.form.value;
                    if (!Email) return;
                    const Password = group.controls.Password.form.value;
                    if (!Password) return;
                    const [good, error] = await data.server.serverSetupVerifiedCustomer({
                      customerID, Password
                    }).try();
                    group.controls.CreateAccount.errortext = error;
                    if (!good) return;
                    group.controls.Password.hidden = true;
                    group.controls.CreateAccount.hidden = true;
                    group.controls.AWSID.form.setValue("created");
                    group.form.updateValueAndValidity();

                    // this code shows the user an error box before throwing, so we don't need to do anything
                    const [good2, error2] = await data.server.serverSendStorageAgreementLink({
                      customerID
                    }).try();

                    storageAgreementTried = true;
                    storageAgreementError = error2;
                    storageAgreementSent = good2;
                    group.form.markAsPristine();
                    group.form.updateValueAndValidity();

                    page.subs.add(() => { emitGlobalRefresh(); });

                  }}>Create Account</ButtonAwait>
              </BlockStack>
            }
          }),
        }
      });
      group.controls.Password.hidden = true;
      page.subs.add(page.setupComplete.subscribe(() => {
        const {
          EmailVerified,
          StorageAgreementStatus
        } = group.form.value;

        if (EmailVerified && !changeEmail) {
          setStageEmailVerified();
        } else {
          group.controls.EmailSent.hidden = !countValidCodes;
          group.controls.Code.hidden = !countValidCodes;
          group.controls.CheckCode.hidden = !countValidCodes;
        }

        if (StorageAgreementStatus !== "none") {
          storageAgreementTried = true;
          storageAgreementSent = true;
        }
      }));

      return group;
    },
    useTitle: () => "",
    useWhenLoaded: (page, onClose) => {
      return <BlockStack>{renderGroupControls(page.group, "CREATE")}</BlockStack>
    },
    onSaveValue: async (page, value) => {
      throw "no effect";
    }
  });
}


function BlockStackBox({ children, padding = "300", gap = "300" }: PropsWithChildren<{ padding?: BoxProps["padding"], gap?: BlockStackProps["gap"] }>) {
  return (
    <Box padding={padding}>
      <BlockStack gap={gap}>
        {children}
      </BlockStack>
    </Box>
  )
}
async function prompt<T>(header: string, message: string, onConfirm: () => Promise<T>): Promise<T | null> {
  return await new Promise<T | null>((resolve, reject) => {
    globalConfirmation.confirm({
      message,
      header,
      // icon: 'pi pi-info-circle',
      accept: () => {
        Promise.resolve()
          .then(() => onConfirm())
          .then(resolve, reject);
      },
      reject: () => {
        resolve(null);
      }
    })

  });
};

