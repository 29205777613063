import { is, jsonify, Modes, ok, Prisma, TABLE_NAMES, truthy } from "common";
import { DataPage } from "./question-dialog";
import { QuestionGroup, QuestionInputNumber, QuestionSelect, QuestionSimple, QuestionSubGroup } from "./question-base";
import { Badge, BlockStack, BlockStackProps, Box, BoxProps, Button, ButtonGroup, DataTable, Divider, Icon, InlineStack, Loading, ModalProps, Spinner, Text } from "@shopify/polaris/index";
import { PropsWithChildren, useCallback, useContext, useLayoutEffect, useMemo, useRef, useState, useSyncExternalStore } from "react";
import { ButtonAwait, ConfirmationService, emitGlobalRefresh, EventEmitter, globalConfirmation, globalMessage, Injector, ModalHostEvents, StatusBadge, useAngular, useAsyncEffect, useObservable, useObserver, useRefresh } from "react-utils";
import { InfoIcon } from "@shopify/polaris-icons";
import { UIService } from "./ui.service";
import { DataService } from "data-service";
import { DataListColumn } from "./question-classes";
import { DateTime } from "luxon";
import { dollarsToCents } from "../pages/page-table-list";
import { showPageEditModal } from "./showPageEditModal";
import { renderGroupControls } from "../components/QuestionForm";
import { from, Observable, of, startWith } from "rxjs";
import * as PrismaExtra from "prisma-client";
import { branchesQuery, BranchSelectorContext, CurBranch, useBranchSelector } from "./useBranchSelector";
import { FormsQuestionService } from "./forms-question.service";
import { Generator } from "./Generator";
import { Validators } from "@angular/forms";
/** shenanigans: this changes the type into what we would see in client */
function jsonify<T>(obj: T): jsonify<T> {
  return obj as any;
}

export async function showCustomerCreateModal() {
  showPageEditModal({
    table: "Customer",
    id: "",
    group: (page, mode, injector) => {
      const BranchList = new EventEmitter<Awaited<ReturnType<typeof branchesQuery>>>();
      const ui = injector.get(UIService);
      const data = injector.get(DataService);
      const group = new QuestionGroup({
        __typename: "Customer",
        controls: {
          Email: new QuestionSimple("InputText", {
            title: "Email",
            required: true,
          }),
          CustomerType: new QuestionSelect({
            options: of(Object.values(PrismaExtra.CustomerType).map(e => ({ value: e }))),
            optionLabels: ui.makeBasicColumns([{ key: "value" }]),
            optionValue: "value",
            display: "buttons",
            title: "Customer Type",
            required: true,
          }),
          BillingDay: new QuestionInputNumber({
            inputMode: "integer",
            title: "Billing Day of the Month"
          }),
          // Generator.Group("CustomerBillingInfo", "CREATE", ui,
          //   ["Name", "Address", "Phone", "Notes", "LateFeeExempt", "TaxExempt"]
          // )
          billing: new QuestionSubGroup(ui.schema.CustomerBillingInfo("CREATE"), {
            title: "Billing Info",
          }),
          HowTheyFoundUs: new QuestionSimple("InputText", {
            title: "How They Found Us",
          }),
          firstContactBranch: new QuestionSelect({
            display: "dropdown",
            options: BranchList,
            optionLabels: ui.makeBasicColumns([{ key: "DisplayName" }]),
            optionValue: "id",
            title: "First Contact Branch",
            required: true,
            onLoadHook: async tag => {
              if (data.userRole === "web_admin" || data.status.Branches.find(e => e.branch.BranchType === "CENTRAL")) {
                BranchList.emit(await tag.addPromise(branchesQuery()));
              } else {
                BranchList.emit(data.status.Branches.filter(e => e.branch.BranchType === "DEALER").map(e => e.branch));
              }
            }
          }),
        }
      });
      group.subs.add(group.form.valueChanges.pipe(startWith(group.form.value)).subscribe((e) => {
        group.controls.BillingDay.hidden = e.CustomerType !== PrismaExtra.CustomerType.Commercial;
      }));
      group.controls.billing.group.controls.Name.required = true;
      group.controls.billing.group.controls.Name.form.addValidators(Validators.required);
      group.controls.billing.group.controls.Name.form.updateValueAndValidity();
      return group;
    },
    useTitle: () => "",
    useWhenLoaded: (page, onClose) => {

      return <BlockStack>{renderGroupControls(page.group, "CREATE")}</BlockStack>
    },
    onSaveValue: async (page, value) => {
      console.log(value);
      const { billing, Email, CustomerType, BillingDay, HowTheyFoundUs, firstContactBranch } = value;
      const isCommercial = CustomerType === PrismaExtra.CustomerType.Commercial;
      ok(is<PrismaExtra.CustomerType>(CustomerType, !!CustomerType && CustomerType in PrismaExtra.CustomerType));
      ok(billing);
      ok(Email);
      ok(firstContactBranch);
      const [good, error, result] = await page.data.server.serverCreateCustomerMinimum({
        Email,
        billing: value.billing as any,
        CustomerType,
        BillingDay: isCommercial ? BillingDay : null,
        HowTheyFoundUs,
        firstContactBranch,
      }).try();
      if (good) page.injector.get(FormsQuestionService).showEdit("Customer", result.customerID);
      page.onClose();
    }
  });
}


function BlockStackBox({ children, padding = "300", gap = "300" }: PropsWithChildren<{ padding?: BoxProps["padding"], gap?: BlockStackProps["gap"] }>) {
  return (
    <Box padding={padding}>
      <BlockStack gap={gap}>
        {children}
      </BlockStack>
    </Box>
  )
}
async function prompt<T>(header: string, message: string, onConfirm: () => Promise<T>): Promise<T | null> {
  return await new Promise<T | null>((resolve, reject) => {
    globalConfirmation.confirm({
      message,
      header,
      // icon: 'pi pi-info-circle',
      accept: () => {
        Promise.resolve()
          .then(() => onConfirm())
          .then(resolve, reject);
      },
      reject: () => {
        resolve(null);
      }
    })

  });
};

