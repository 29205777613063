import { BlockStack, Card, DataTable, MenuGroupDescriptor, Page, Spinner } from "@shopify/polaris/index";
import { DataService } from "data-service";
import { useNavigate } from "react-router";
import { useAngular, useAsyncEffect, useBind } from "react-utils";
import { SelectTable2 } from "../tables/SelectTable";
import { useCallback, useMemo, useState } from "react";
import { CustomColumnState } from "../tables/CustomColumnState";
import { cubesDateTime, ObjectPathTree, ok, PrismaArgs, TABLE_NAMES, TableViewColumn, truthy } from "common";
import { TableView, TableViewClass } from "../tables/table-views";
import { Buffer } from "buffer";
import { useBranchSelector } from "../utils";
import { CustomTable, useTableData } from "../tables/useCustomTable";
import { DatePickerComboBox } from "../components/DatePicker";
import { QuestionAutoComplete } from "../components/QuestionAutoComplete";
import { SelectDropdown } from "../components/Select";
import { QuestionInputNumberComp } from "../components/QuestionInputNumber";
import { fetchAuthSession } from "@aws-amplify/auth";
import { Location } from "@aws-sdk/client-location";
import { DateTime } from "luxon";

export function uuidFromBase64(id: string): string {
  const hex = Buffer.from(id.replace("-", "+").replace("_", "/") + "==", "base64").toString("hex");
  return [hex.slice(0, 8), hex.slice(8, 12), hex.slice(12, 16), hex.slice(16, 20), hex.slice(20)].join("-");
}

function parseTxnID(txnID: string) {
  //`${new Date().toISOString()}-${uuidBase64(this.user.id)}-${otherType}-${uuidBase64(otherID)}`
  const reg = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z)-([A-Za-z0-9_-]{22})-([A-Za-z0-9_-]+)-([A-Za-z0-9_-]{22})$/;
  const match = txnID.match(reg);
  if (!match) return null;
  const [date, user, type, id] = match.slice(1);
  return { date, user: uuidFromBase64(user), type, id: uuidFromBase64(id) };
}
class Col {
  constructor(public name: string, public type: 'text' | 'numeric', public val: string | number | null | undefined) { }
}
export function AppTesting() { return AppTesting5(); }
function TransactionTypeTable() {
  const { get } = useAngular();
  const data = get(DataService);

  const { loading, result: rows } = useAsyncEffect(async () => {

    const txns = await data.prisma.transaction.findMany({
      // where: {id: "941e49df-4be9-43e6-ace8-8f60a9a87ae5"},
      include: {
        branchDiscountLedgerLine: true,
        branchLedgerLine: true,
        centralDiscountLedgerLine: true,
        centralLedgerLine: true,
        customerLedgerLine: true,
        divisionDiscountLedgerLine: true,
        divisionLedgerLine: true,
        ownerLedgerLine: true,
        salesTaxLedgerLine: true,
        paymentLine: true,
        invoiceLine: true,
      }
    });
    const USE_VALID = true;
    console.log(txns.filter(e => e.VoidSince || e.IS_TESTING).length);

    const rows = txns.filter(e => e.paymentLine && !e.VoidSince && !e.IS_TESTING).map(e => {
      const txn = e.paymentLine?.txnID ? parseTxnID(e.paymentLine.txnID) : null;
      const customer = !!e.customerLedgerLine;
      const branch = !!e.branchLedgerLine;
      const owner = !!e.ownerLedgerLine;
      const division = !!e.divisionLedgerLine;
      const central = !!e.centralLedgerLine;
      const salestax = !!e.salesTaxLedgerLine.length;
      const payment = !!e.paymentLine;
      const invoice = !!e.invoiceLine;
      const ledger = e.paymentLine?.PaymentLedger;
      const [valid, desc] = (() => {

        if (payment && invoice) return [false, "Invoice & Payment"];

        if (payment) {
          const breakout = [branch, owner, division, central, salestax];
          const titles = ["Branch", "Owner", "Division", "Central", "Sales Tax"];
          // DOUBLE ENTRY ACCOUNTING:
          // when the customer makes a payment, the money is recieved into the customer's holding balance
          // then assigned to invoice lines which specify how the money is distributed.
          // it's not exactly double entry and this is where most of the problems will occur.


          const length = breakout.filter(e => e).length;
          const hasTxnID = !!e.paymentLine?.txnID;
          const hasUser = !!txn?.user;

          if (customer && length === 0) return [] // [hasTxnID, "Customer Payment to System"];
          if (customer && length === 1) return [hasUser, "Customer Payment to " + titles[breakout.indexOf(true)]];
          if (customer && length > 1) return [false, "Invalid Payment"];

          if (!customer && length === 1) {
            if (e.branchLedgerLine && e.branchLedgerLine.Amount > 0) return [false, "Branch Payment"];
            if (e.ownerLedgerLine && e.ownerLedgerLine.Amount > 0) return [false, "Owner Payment"];
            if (e.divisionLedgerLine && e.divisionLedgerLine.Amount > 0) return [false, "Division Payment"];
            return [hasTxnID, "Payout to " + titles[breakout.indexOf(true)]];
          }
          if (!customer && length === 0) return [false, "Empty Payout"];
          if (!customer && length > 1) return [false, "Invalid Payout"];
        }

        return [true, "Unknown"];
        // if (e.paymentLine) {
        //   if (e.customerLedgerLine && e.branchLedgerLine) return "Payment to Branch";
        //   if (e.customerLedgerLine && e.ownerLedgerLine) return "Payment to Owner";
        //   if (e.customerLedgerLine && e.divisionLedgerLine) return "Payment to Division";
        //   // why aren't we using central here? That could be super useful, 
        //   // although the balance would always be negative.
        //   if (e.customerLedgerLine) return "Payment to Central";
        //   if (!e.customerLedgerLine && e.branchLedgerLine) return "Payout to Branch";
        //   if (!e.customerLedgerLine && e.ownerLedgerLine) return "Payout to Owner";
        //   if (!e.customerLedgerLine && e.divisionLedgerLine) return "Payout to Division";
        //   if (!e.customerLedgerLine && e.centralLedgerLine) return "Payout to Central";
        // }
        // if (e.invoiceLine) {
        //   if (e.customerLedgerLine && e.branchLedgerLine && !e.ownerLedgerLine) return "Branch Charge";
        //   if (e.customerLedgerLine && e.ownerLedgerLine && e.branchLedgerLine && e.centralLedgerLine) return "Unit Rental";
        // }
      })();



      return (false && valid === true || valid === undefined) ? null : [
        new Col("Date", "text", e.Date),
        new Col("Type", "text", e.invoiceLine ? "Invoice" : e.paymentLine ? "Payment" : "Unknown"),
        new Col("Description", "text", desc),
        new Col("Customer Amount", "numeric", e.customerLedgerLine?.Amount.toString() || ""),
        new Col("Branch Amount", "numeric", e.branchLedgerLine?.Amount.toString() || ""),
        new Col("Owner Amount", "numeric", e.ownerLedgerLine?.Amount.toString() || ""),
        new Col("Division Amount", "numeric", e.divisionLedgerLine?.Amount.toString() || ""),
        new Col("Central Amount", "numeric", e.centralLedgerLine?.Amount.toString() || ""),
        new Col("Payment Status", "text", e.paymentLine?.PaymentStatus || ""),
        // new Col("Txn ID", "text", e.paymentLine?.txnID?.slice(0, 20) || ""),
        new Col("Sales Tax", "numeric", e.salesTaxLedgerLine.length.toString()),
        new Col("Branch Discount", "text", e.branchDiscountLedgerLine ? "true" : "false"),
        // new Col("Description", "text", e.Description),
        new Col("Void", "text", e.VoidSince ? "true" : "false"),
        new Col("Testing", "text", e.IS_TESTING ? "true" : "false"),
        // new Col("ID", "text", e.id),
      ]
    }).filter(truthy);
    console.log(rows);
    return rows;

  }, undefined, undefined, []);

  return <Page fullWidth>
    <BlockStack gap="400">
      {rows && <DataTable
        hoverable
        columnContentTypes={rows[0].map(e => e.type)}
        headings={rows[0].map(e => e.name)}
        rows={rows.map(e => e.map(e => e.val))}
      />}
    </BlockStack>
  </Page >

}



export function AppTesting2() {
  const { get } = useAngular();
  const data = get(DataService);
  const actionGroups: MenuGroupDescriptor[] = [];
  const { branchSelectorActionGroup, curBranch, curBranchTitle } = useBranchSelector();

  if (branchSelectorActionGroup && data.status.branchType === "CENTRAL")
    actionGroups.push(branchSelectorActionGroup);

  const [sort, setSort] = useState<"Invoice" | "Payment">("Invoice");

  const { loading, result: rows } = useAsyncEffect(async () => {
    if (!curBranch) return null;
    const branches = await data.server.queryInvoicePaymentJoins({ branch: { id: curBranch } });
    if (!branches.length) return null;
    const branch = branches[0][1];
    const joins = branch.createdJoins;
    const paymentJoins = new Map();
    const invoiceJoins = new Map();
    joins.forEach(e => {
      if (e.paymentLineID) paymentJoins.getForced(e.paymentLineID, []).push(e);
      if (e.invoiceLineID) invoiceJoins.getForced(e.invoiceLineID, []).push(e);
    });
    const payments = new Map([...branch.completepayments, ...branch.pendingpayments].map(e => [e.line.line.paymentLine!.id, e]));
    const invoices = new Map([...branch.completeinvoices, ...branch.pendinginvoices].map(e => [e.line.line.invoiceLine!.id, e]));
    const rows = branch.createdJoins.map(e => [
      // new Col("paymentLineID", "text", e.paymentLineID),
      // new Col("invoiceLineID", "text", e.invoiceLineID),
      // new Col("fee", "numeric", e.fee),
      new Col("Date", "text", invoices.get(e.invoiceLineID)?.line.line.Date),
      new Col("invoice_amount", "numeric", e.invoice_amount),
      new Col("invoice_balance", "numeric", e.invoice_balance),
      new Col("amount", "numeric", e.amount),
      new Col("fee", "numeric", invoices.get(e.invoiceLineID)?.line.fee),
      new Col("branch", "text",
        (!!payments.get(e.paymentLineID)?.line.line.customerLedgerLine?.customerID
          && !!payments.get(e.paymentLineID)?.line.line.branchLedgerLine?.branchID) ? "branch" : "central"),
      new Col("payment_balance", "numeric", e.payment_balance),
      new Col("payment_amount", "numeric", e.payment_amount),
      new Col("Date", "text", payments.get(e.paymentLineID)?.line.line.Date),
    ]);
    rows.sort((a0, b0) => {
      const a = a0.last()!;
      const b = b0.last()!;
      const a1 = typeof a.val === "string" ? a.val : "";
      const b1 = typeof b.val === "string" ? b.val : "";
      return a1.localeCompare(b1);
    });
    return rows;

  }, undefined, undefined, [curBranch]);
  return <Page fullWidth actionGroups={actionGroups}>
    <BlockStack gap="400">
      {rows && <DataTable
        hoverable
        columnContentTypes={rows[0].map(e => e.type)}
        headings={rows[0].map(e => e.name)}
        rows={rows.map(e => e.map(e => e.val))}
      />}
    </BlockStack>
  </Page>
}

function AppTesting3() {
  const { get } = useAngular();
  const data = get(DataService);

  const tableData = useTableData(() => [
    // { key: "Amount", title: "Amount", filterType: "currency", calculate: e => e.Amount },
    // { key: "Name", title: "Name", calculate: e => e.customer?.billing?.Name },
    // { key: "Email", title: "Email", calculate: e => e.customer?.Email },
    // { key: "Unit", title: "Unit", calculate: e => e.unit?.Name },
    // { key: "notPaid", title: "Not Paid", filterType: "numeric", calculate: e => e.notPaid },
    { key: "customerID", title: "Customer ID", calculate: e => e.id },
    { key: "status", title: "Status", calculate: e => e.status.join(", ") },
  ], [], async () => {

    const customers = await data.prisma.customer.findMany({
      select: {
        id: true,
        Email: true,
        billing: true,
        LedgerLines: {
          select: PrismaArgs("CustomerLedger", x => [
            x._.Amount.__,
            x._.line._.Date.__,
            x._.line._.id.__,
            x._.line._.invoiceLine._.autopayAttempts._.paymentLine._.id.__,
            x._.line._.invoiceLine._.autopayAttempts._.paymentLine._.PaymentStatus.__,
            x._.line._.invoiceLine._.autopayAttempts._.success.__,
            x._.line._.invoiceLine._.id.__,
            x._.line._.invoiceLine._.paidOn.__,
            x._.line._.invoiceLine._.rental._.InvoiceLines._.id.__,
            x._.line._.invoiceLine._.rental._.InvoiceLines._.paidOn.__,
            x._.line._.invoiceLine._.rental._.InvoiceLines._.autopayAttempts._.success.__,
            x._.line._.invoiceLine._.rental._.InvoiceLines.orderBy.___({ line: { Date: "asc" } }),
            x._.line._.invoiceLine._.rental._.RentalStatus.__,
            x._.line._.invoiceLine._.rental._.unit._.currentBranch._.DisplayName.__,
            x._.line._.invoiceLine._.rental._.unit._.id.__,
            x._.line._.invoiceLine._.rental._.unit._.Name.__,
            x._.line._.invoiceLine._.rental._.unit._.unitType._.Name.__,
          ] as const).select,
          // we can't filter out lines that are already paid because we need to make sure this isn't the first line for this rental
          where: { line: { VoidSince: null, invoiceLine: { item: { ItemType: "Rental" } } } },
        }
      },
      where: {
        AllRentals: { some: { RentalStatus: { notIn: ["Reserved", "Scheduled", "Archived", "SoldToCustomer"] } } },
        LedgerLines: { some: { line: { VoidSince: null, invoiceLine: { paidOn: null, item: { ItemType: "Rental" } } } }, },
      }
    });

    const rentals = (await data.prisma.rental.findMany({
      select: PrismaArgs("Rental", x => [
        x._.id.__,
        x._.customer._.id.__,
        x._.customer._.Email.__,
        x._.customer._.billing._.Name.__,
        x._.InvoiceLines._.id.__,
        x._.InvoiceLines._.paidOn.__,
        x._.InvoiceLines._.autopayAttempts._.success.__,
        x._.InvoiceLines.orderBy.___({ line: { Date: "asc" } }),
        x._.RentalStatus.__,
        x._.unit._.currentBranch._.DisplayName.__,
        x._.unit._.id.__,
        x._.unit._.Name.__,
        x._.unit._.unitType._.Name.__,
      ] as const).select,
    })).map(e => ({
      ...e,
      notPaid: e.InvoiceLines.filter(e => !e.paidOn).length
    }));

    const customers2 = new Map<string, typeof rentals>();

    for (const e of rentals) if (e.customer) customers2.getForced(e.customer.id, []).push(e);

    return Array.from(customers2.entries())
      .map(([id, e]) => ({ id, status: Array.from(new Set(e.map(e => e.notPaid))) }))
      .filter(e => e.status.length > 1);

  }, [], "id");


  return <CustomTable
    tableData={tableData}
    curTab={0}
    setTab={() => { }}
    showFilters={false}
    resourceName={{ singular: "Customer Rental Line", plural: "Customer Rental Lines" }}
    pagination
  />;
}

function AppTesting4() {
  const selectionChange = useBind(console.log, console, "selectionChange");
  const onClickRow = useBind(console.log, console, "onClickRow");
  const { cols, rows, idcol } = useTableData(() => [
    // { key: "Amount", title: "Amount", filterType: "currency", calculate: e => e.Amount },
    // { key: "Name", title: "Name", calculate: e => e.customer?.billing?.Name },
    // { key: "Email", title: "Email", calculate: e => e.customer?.Email },
    // { key: "Unit", title: "Unit", calculate: e => e.unit?.Name },
    // { key: "notPaid", title: "Not Paid", filterType: "numeric", calculate: e => e.notPaid },
    { key: "customerID", title: "Customer ID", calculate: e => e.id },
    { key: "status", title: "Status", calculate: e => e.test },
  ], [], async () => {
    return [{ id: "test", test: "tesst" }];
  }, [], "id");
  return <SelectTable2
    selectable="multiple"
    selectionChange={selectionChange}
    cols={cols}
    rows={rows}
    idcol={idcol}
    onClickRow={onClickRow}
  />;
}



const onSearch = async (input: string) => {
  const { credentials } = await fetchAuthSession();
  const res = await new Location({
    region: "us-east-2",
    credentials,
  }).searchPlaceIndexForSuggestions({
    IndexName: "CustomerLocationLookup",
    FilterCountries: ["USA"],
    Text: input,
  });
  return res.Results?.filter(truthy) ?? [];
}


function CompTest() {
  const [valueAddress, setValueAddress] = useState<{ Text: string | undefined } | null>(null);
  const [valueCalendar, setValueCalendar] = useState<string | null>("");
  const [valueCurrency, setValueCurrency] = useState<number | null>(2500);
  const [valueDecimal, setValueDecimal] = useState<number | null>(2500);

  return <Card>
    <BlockStack gap="300">
      <DatePickerComboBox valueType="string" value={valueCalendar} onChange={setValueCalendar} label="Date picker" autoComplete="off" />
      <QuestionAutoComplete
        value={valueAddress}
        onChange={setValueAddress}
        label="Auto complete"
        onSearch={onSearch}
        autoComplete="off"
        optionLabel="Text"
        optionValue={"Text"}
        searchOnFocus
      />
      {[undefined, "Select an option..."].map(placeholder => {
        const [valueSelect1, setValueSelect1] = useState<string | null>("");
        return <>
          <SelectDropdown
            label="Select"
            options={[
              { label: "Option 1", value: "1" },
              { label: "Option 2", value: "2" },
              { label: "Option 3", value: "3" },
            ]}
            value={valueSelect1 ?? undefined}
            placeholder={placeholder}
            onChange={(v: any) => { setValueSelect1(v) }}
          />
          <SelectDropdown
            label="Select"
            options={[]}
            value=""
            placeholder={placeholder}
            onChange={(v: any) => console.log(v)}
          />
        </>;
      })}
      <QuestionInputNumberComp
        inputMode="currency"
        value={valueCurrency}
        onChange={setValueCurrency}
        mode="UPDATE"
        label="Currency"
        id="currency"
        required={false}
        setErrors={() => { }}
        onBlur={() => { }}
        autoComplete="off"
        disabled={false}
        helpText={undefined}
        readOnly={false}
      />
      <QuestionInputNumberComp
        inputMode="decimal"
        value={valueDecimal}
        onChange={setValueDecimal}
        mode="UPDATE"
        label="Decimal"
        id="decimal"
        required={false}
        setErrors={() => { }}
        onBlur={() => { }}
        autoComplete="off"
        disabled={false}
        helpText={undefined}
        readOnly={false}
      />
    </BlockStack>
  </Card>
}

function AppTesting5() {
  const data = useAngular().get(DataService);


  const tableData = useTableData(() => [
    // { key: "Amount", title: "Amount", filterType: "currency", calculate: e => e.Amount },
    // { key: "Name", title: "Name", calculate: e => e.customer?.billing?.Name },
    // { key: "Email", title: "Email", calculate: e => e.customer?.Email },
    // { key: "Unit", title: "Unit", calculate: e => e.unit?.Name },
    // { key: "notPaid", title: "Not Paid", filterType: "numeric", calculate: e => e.notPaid },
    // { key: "customerID", title: "Customer ID", calculate: e => e.id },
    // { key: "status", title: "Status", calculate: e => e.status.join(", ") },
    // { key: "customerID", title: "Customer ID", calculate: e => e.customerID },
    // { key: "lineID", title: "Line ID", calculate: e => e.lineID },
    { key: "Date", title: "Date", sort: -1, calculate: e => e.line.line.Date },
    { key: "Name", title: "Name", calculate: e => e.customer?.billing?.Name, link: e => `/Customer/edit/${e.customerID}` },
    { key: "Balance", title: "Balance", filterType: "currency", calculate: e => e.customerBalance, },
    { key: "CustomerType", title: "Customer Type", calculate: e => e.customer?.CustomerType },
    { key: "Unit", title: "Unit", calculate: e => e.rental?.unit.Name, },
    { key: "Branch", title: "Branch", calculate: e => e.rental?.unit.currentBranch.DisplayName },
    { key: "Amount", title: "Amount", filterType: "currency", calculate: e => e.line.amount },
    { key: "Balance", title: "Balance", filterType: "currency", calculate: e => e.balance },
    { key: "Has Late Fee", title: "Has Late Fee", filterType: "boolean", calculate: e => e.line.line.invoiceLine?.lateFeeID },
    { key: "Charge Late Fee", title: "Charge Late Fee", filterType: "boolean", calculate: e => e.chargeLateFee },
  ], [], async () => {
    const oldBefore = DateTime.now().minus({ days: 5 });


    const rentalSet = new Set<string>();
    const customers = new Map(await data.server.queryInvoicePaymentJoins({ customer: {}, includeApprovedCustomerPayments: true }));
    type Customer = typeof customers extends Map<infer K, infer V> ? V : never;
    const res: Map<string, Customer["pendinginvoices"]> = new Map();
    const lineLateFee = new Set<string>();
    const customerBalances = new Map<string, number>();

    for (const [id, customer] of customers) {
      const pInvBal = customer.pendinginvoices.reduce((n, e) => n + e.balance, 0);
      const pPayBal = customer.pendingpayments.reduce((n, e) => n + e.balance, 0);
      // payments are negative, so we add everything together to get the total balance
      const customerBalance = pInvBal + pPayBal;
      // don't charge customers with a total balance less than $5.00
      if (customerBalance < 500) continue;
      for (const e of customer.pendinginvoices) {
        ok(e.line.line.invoiceLine);
        const ItemType = e.line.line.invoiceLine.item.ItemType;
        if (ItemType !== "Rental") continue;
        const lineDate = cubesDateTime.parse(e.line.line.Date, "09"); // same time as autopay runs
        res.getForced(id, []).push(e);
        if (e.line.line.invoiceLine?.rental?.id)
          rentalSet.add(e.line.line.invoiceLine.rental.id);
        if (+lineDate > Date.now()) continue;
        if (+lineDate < +oldBefore) continue;
        if (e.line.line.invoiceLine.lateFeeID) continue;
        lineLateFee.add(e.line.line.id);
      }
    }

    const customers2 = new Map(await data.prisma.customer.findMany({
      where: { id: { in: Array.from(res.keys()) } },
      select: { id: true, billing: true, CustomerType: true, IS_TESTING: true },
    }).then(e => e.map(e => [e.id, e] as const)));

    const rentals = new Map(await data.prisma.rental.findMany({
      where: { id: { in: Array.from(rentalSet) } },
      select: PrismaArgs("Rental", x => [
        x._.id.__,
        x._.unit._.Name.__,
        x._.unit._.currentBranch._.id.__,
        x._.unit._.currentBranch._.DisplayName.__,
      ] as const).select,
    }).then(e => e.map(e => [e.id, e] as const)));
    rentals.delete(""); // just in case

    return Array.from(res.entries()).map(([id, e]) => e.map(f => ({
      customerID: id,
      customer: customers2.get(id),
      customerBalance: customerBalances.get(id),
      lineID: f.line.line.id,
      chargeLateFee: lineLateFee.has(f.line.line.id),
      rental: rentals.get(f.line.line.invoiceLine?.rental?.id ?? ""),
      ...f
    }))).flat();

  }, [], "lineID");



  return <CustomTable
    tableData={tableData}
    curTab={0}
    setTab={() => { }}
    showFilters={false}
    resourceName={{ singular: "Customer Rental Line", plural: "Customer Rental Lines" }}
    pagination
  />;
}
