/* eslint-disable no-lone-blocks */
import React, { useDeferredValue, useLayoutEffect, useRef } from "react";
import {
  IndexFilters, TabProps
} from '@shopify/polaris';
import { useState, useMemo } from 'react';
import { ColumnBase, DataListColumn, UIService } from '../utils';
import { DataQueryGraph, ok, SPPI, TABLE_NAMES, truthy } from "common";
import { DataService } from "data-service";
import { useAsyncEffect, useAngular, useObservable } from "react-utils";
import { FormsQuestionService } from '../utils';
import { useCallback } from "react";
import { Router } from "@angular/router";
import { NgZone } from "@angular/core";
import { AppliedFilterProps, convertFiltersToQuery, useFiltersWithViews } from "./useFilters";
import { MaybeArray, TableView, PrismaWhereQuery, MaybeTableViewArray, TableViewOptions } from "./table-views";
import { firstValueFrom } from "rxjs";
import { IndexFiltersPrimaryAction } from "@shopify/polaris/components/IndexFilters";
import { Observable } from 'rxjs';

import { useRefresh } from 'react-utils';
import { CustomColumnState } from "./CustomColumnState";
import { useNavigate } from "react-router";
import { TableRowDispatch } from "./TableRowRedux";
import { SelectTable2, useTableListTree } from "./SelectTable";

export function useTableListSimple<R extends any[] = any[]>({ table, view }: {
  table: TABLE_NAMES,
  view: TableView,
}) {


  const { get } = useAngular();
  const fq = get(FormsQuestionService);
  const ui = get(UIService);
  const data = get(DataService);

  const tableView = view instanceof TableViewOptions ? view :
    useMemo(() => TableViewOptions.fromViewWithOptions(table as TABLE_NAMES, view, view), [table, view]);

  const { cols, rows, idcol, value, setValue, arrayList, arraySort } = useTableListTree(tableView);

  const customs = useMemo(() => table ? new CustomColumnState(table, data, cols) : undefined, [table, cols]);

  const AND: PrismaWhereQuery[] | null = useSimpleAND(cols, view, table);
  const badgeCounts = useMemo(() => new Map(), []);

  const { loading } = useTableListEffect(
    cols, setValue, data, table, arrayList, arraySort, badgeCounts, customs, JSON.stringify(AND)
  );

  return { cols, rows: rows as R, idcol, loading, arraySort };

}

function useSimpleAND(
  // tree: { cols: readonly ColumnBase[];[TableCheck]?: never; },
  // tree: EditTreeAccessor<any>,
  cols: DataListColumn[],
  view: TableView,
  table: string
) {

  const viewAND = view && Array.isArray(view.AND) && view.AND;

  const AND: PrismaWhereQuery[] | null = useMemo(() => {
    return [
      ...viewAND || [],
      // ...convertFiltersToQuery(convertViewToFilters(cols, view.filters)),
    ];
  }, [viewAND, cols, view]);
  return AND;
}

export function TableListSimple({ table, view, hiddenColumns = [], emptyState }: {
  table: TABLE_NAMES,
  view: TableView,
  hiddenColumns?: SPPI[],
  emptyState?: React.ReactNode,
}) {

  const { get } = useAngular();
  const fq = get(FormsQuestionService);

  const { cols, rows, idcol, loading, arraySort } = useTableListSimple({ table, view });

  cols.forEach(e => { if (hiddenColumns.contains(e.key)) e.hidden = true; });

  return <SelectTable2 {...({
    cols, rows, idcol, firstSort: arraySort[0],
    onClickRow: (id) => { fq.onClickEvent({ action: "edit", table, id }); },
    emptyState: loading ? null : emptyState,
  })} />;

}

export interface TableListInnerProps {
  /** Whatever the root of views.columns is. */
  table: TABLE_NAMES;
  /** This needs to be cached with useMemo */
  views: MaybeArray<TableView> | readonly TableView[] | (TableView | TableViewOptions)[]
  loadingMarkup?: (curView: number) => React.ReactNode;
  emptyMarkup?: (curView: number) => React.ReactNode;
  hiddenColumns?: SPPI[];
  hideFilters?: boolean;
  primaryAction?: IndexFiltersPrimaryAction | undefined,
  resourceName?: { singular: string, plural: string },
  firstView?: number;
  /** Called via useLayoutEffect when the current view (or this function) changes */
  onViewChange?: ({ view }: { view: TableView }) => void;
  onSelectRow?: (id?: string, row?: unknown) => void;
  /** called right after makeDataListColumns(), which is called when the selected view is initialized, but before CustomColumnState is created */
  colsHook?: (cols: readonly ColumnBase[]) => void;
  selectable?: "single" | "multiple";
  selectionChange?: (rows: Set<string>) => void;
  // queryColumns?: SPPI[];
  defaultSort?: boolean;
  rowsMapper?: (rows: any[], cols: readonly ColumnBase[], idcol: ColumnBase) => any[];
  pagination?: boolean;
  extraAppliedFilters?: AppliedFilterProps[]
}



export function useTableListInner({
  table,
  views,
  firstView = 0,
  onViewChange,
  loadingMarkup,
  emptyMarkup,
  hiddenColumns = [],
  hideFilters = false,
  resourceName,
  onSelectRow: onSelectRowProp,
  extraAppliedFilters,
  colsHook,
  selectable,
  selectionChange,
  defaultSort = true,
  rowsMapper,
  pagination,
}: TableListInnerProps) {

  const { get } = useAngular();
  const fq = get(FormsQuestionService);
  const ui = get(UIService);
  const data = get(DataService);

  ok(views.length > 0);

  const [curView, setView] = useState(firstView);

  const view = views[curView];

  ok(view);

  useLayoutEffect(() => { if (view) onViewChange?.({ view }); }, [onViewChange, view]);

  const tableView = view instanceof TableViewOptions ? view :
    useMemo(() => TableViewOptions.fromViewWithOptions(table as TABLE_NAMES, view, view, defaultSort), [table, view]);

  const { idcol, cols, rows, value, setValue, arrayList, arraySort } = useTableListTree(tableView, colsHook, defaultSort);

  const customs = useMemo(() => table ? new CustomColumnState(table, data, cols) : undefined, [table, cols]);

  const colsMap = new Map(cols.map(e => [e.key, e]));

  const { curAppliedFilters, setAppliedFilters, filters, onClearAll, curMode, setMode } = useFiltersWithViews(cols, views, curView, setView);

  const emptyView = views.length && curView === -1 || typeof view?.AND === "symbol";

  const viewAND = view && Array.isArray(view.AND) && view.AND;

  const AND: PrismaWhereQuery[] | null = useMemo(
    () => emptyView ? null : [...viewAND || [], ...convertFiltersToQuery(curAppliedFilters)],
    [emptyView, viewAND, curAppliedFilters]
  );

  const badgeCounts = useMemo(() => new Map(views.filter(e => e?.getCount).map(e => [e, undefined] as const)), [views]);

  const { loading } = useTableListEffect(cols, setValue, data, table, arrayList, arraySort, badgeCounts, customs, JSON.stringify(AND));

  cols.forEach(e => { if (hiddenColumns.contains(e.key)) e.hidden = true; });

  const onClickRow = onSelectRowProp && ((id: string) => { onSelectRowProp(id, rows.find(e => idcol.get(e) === id)); });

  const emptyState = loading ? loadingMarkup?.(curView) : emptyMarkup?.(curView);

  const [curQuery, setQuery] = useState("");
  const query = useDeferredValue(curQuery);
  // console.log(curQuery);

  const viewTabs = views.map((item, index) => ({
    content: item.title,
    index,
    onAction: () => { },
    id: `${item.title}-${index}`,
    isLocked: true,
    badge: badgeCounts.get(item),
    disabled: item.hidden
  }) as TabProps);

  // console.log(arraySort);



  const filterMarkup = <IndexFilters
    cancelAction={{
      onAction: () => {
        onClearAll();
        setQuery("");
      }
    }}
    filters={filters}
    appliedFilters={curAppliedFilters}
    onClearAll={onClearAll}
    mode={curMode}
    setMode={setMode}
    onQueryClear={() => { setQuery(""); }}
    queryValue={curQuery}
    onQueryChange={setQuery}
    // primaryAction={{type: "save", onAction: async () => { console.log("save"); return true; }, }}
    hideQueryField={false}
    hideFilters={false}
    tabs={viewTabs}
    selected={curView}
    onSelect={setView}
    canCreateNewView={false}
    disableStickyMode
    disableKeyboardShortcuts
  />;

  const allAppliedFilters = useMemo(() => [
    ...curAppliedFilters,
    ...extraAppliedFilters ?? []
  ], [
    curAppliedFilters,
    extraAppliedFilters
  ]);

  const tableMarkup = <SelectTable2
    key={view.key}
    cols={cols}
    rows={rows}
    idcol={idcol}
    emptyState={emptyState}
    onClickRow={onClickRow}
    firstSort={arraySort[0]}
    loading={loading}
    query={curQuery}
    // selectMultiple={selectMultiple}
    selectable={selectable}
    selectionChange={selectionChange}
    curAppliedFilters={allAppliedFilters}
    resourceName={resourceName}
    rowsMapper={rowsMapper}
    pagination={pagination || undefined}
  />;

  ok(cols.length > 0);

  return {
    emptyState,
    onClickRow,
    arraySort,
    resourceName,
    setMode,
    view,
    curView,
    setView,
    setAppliedFilters,
    customs,
    rows,
    cols,
    colsMap,
    idcol,
    AND,
    filterMarkup,
    tableMarkup,
    /** Optional render function to return markup if you don't need to customize it */
    useMarkup: () => <>
      {hideFilters ? null : filterMarkup}
      {tableMarkup}
    </>
  };

}



export function useViewChangeCallbackSetRouterUrl(table: string, prefix: string, suffix: string, id: string) {
  const { get } = useAngular();
  const router = get(Router);
  const zone = get(NgZone);
  return useCallback(async ({ view }: { view: TableView }) => {
    const [, _table, _view, _id] = router.url.split("?")[0].split("/");
    const viewPart = [prefix, view.key, suffix].filter(truthy).join("-");
    const url = "/" + [table, viewPart, id].filter(truthy).join("/");
    const replaceUrl = table === _table && prefix === _view && id === _id;
    await router.navigateByUrl(url, { replaceUrl });
  }, [table, prefix, suffix, id, router, zone]);
}


// function useTable({
//   cols,
//   rows,
//   emptyState,
//   firstSort,
//   curAppliedFilters,
//   loading,
// }: TableProps): JSX.Element {

//   const notHiddenCols: ColumnBase[] = useMemo(() => cols.filter(e => !e.hidden), [cols]);

//   const { sortedRows: filteredRows, ...sort } = useSortOpts(rows, cols, curAppliedFilters, firstSort);

//   console.log("useTable", { filteredRows, curAppliedFilters, firstSort, notHiddenCols, sort })

//   if (filteredRows.length === 0) return <>{emptyState ?? <EmptyState image="" />}</>;

//   return loading ? <SpinnerBlock /> : <DataTable
//     columnContentTypes={notHiddenCols.map(e => 'text')}
//     headings={notHiddenCols.map(e => (
//       <span className={styles.TableHeading} key={e.key} id={e.key}>{e.title}</span>
//     ))}
//     rows={filteredRows.map(row => notHiddenCols.map(col => valMarkup(col, row)))}
//     sortable={notHiddenCols.map(e => !!e.sorter)}
//     defaultSortDirection={sort.sortDirection}
//     initialSortColumnIndex={sort.sortColumnIndex}
//     onSort={sort.onSort}
//   />
// }


function useTableListEffect(
  cols: DataListColumn[],
  setValue: TableRowDispatch,
  data: DataService,
  table: TABLE_NAMES,
  arrayList: readonly SPPI[],
  arraySort: readonly SPPI[],
  badgeCounts: Map<Pick<TableView<any[]>, "AND">, number | undefined>,
  customs: CustomColumnState | undefined,
  _AND: string,
) {

  const refreshToken2 = useObservable(useRefresh());

  const canceled = useRef(false);

  const AND = JSON.parse(_AND);

  const { loading, result, error } = useAsyncEffect(
    async function () {
      // cols: readonly DataListColumn[], setValue: TableRowDispatch
      // const { AND, arrayList, arraySort, badgeCounts, canceled, customs, data, doRequest, table } = this;
      if (AND === null) {
        setValue({ action: "reset", newValue: [] });
        return false;
      }
      const query = new DataQueryGraph(table, undefined, data.userRole);

      customs?.onLoadHook(query, AND);

      Promise.all([...badgeCounts.keys()].map((e) => {
        const AND = [
          ...Array.isArray(e.AND) ? e.AND : [],
          // ...convertFiltersToQuery(convertViewToFilters(cols, e.filters)),
        ];
        return query.addPromise({
          action: "count",
          table: table,
          arg: { where: AND.length ? { AND } : undefined }
        }).then((r: number) => {
          if (canceled.current) return;
          badgeCounts.set(e, r);
        });
      }));

      const value: any[] = await data.dataGraphQuery(query, "transact", {
        action: "findMany",
        table: table,
        arg: {
          select: data.selectPaths(table, arrayList, false),
          where: AND.length ? { AND } : undefined,
        }
      });

      if (!canceled.current) setValue({
        action: "reset",
        newValue: customs ? customs.onValueFilter(value) : undefined
      });

      return true;

    },
    undefined,
    async () => { canceled.current = true; if (customs) customs.canceled = true; },
    [
      data,
      table,
      arrayList,
      arraySort,
      badgeCounts,
      customs,
      _AND,
      refreshToken2
    ]
  );

  return { loading, result, error, canceled: canceled.current, };

}



function useWindowViewState(views: MaybeTableViewArray<any[]>, table: TABLE_NAMES) {
  const { get } = useAngular();
  const router = get(Router);
  const zone = get(NgZone);
  const navigate = useNavigate();

  const { curView, view } = useMemo(() => {
    const curView = views.length ? views.findIndex(e => router.url === e.url) : 0;
    const view = views.length ? views[curView] : { AND: [], title: `${table} Table`, url: `/${table}/list` };
    return { curView, view };
  }, [router.url, table, views]);


  const goToView = curView === -1 && views.find(e => {
    const url = e.url ?? `/${table}/${e.key}`
    const [, table1, mode1, id1] = router.url.split("?")[0].split("/");
    const [, table2, mode2, id2] = url.split("/");
    return table1 === table2 && mode2.startsWith(mode1);
  });
  useLayoutEffect(() => {
    if (goToView) navigate(goToView.url ?? `/${table}/${goToView.key}`, { replace: true })
  }, [goToView])

  if (!view?.url) suspend(firstValueFrom(router.events));

  const setView = useCallback((newView: number) => {
    const view = views[newView];
    if (!view) return;
    navigate(view.url ?? `/${table}/${view.key}`);
  }, [table, views, router, zone]);

  return { curView, view, setView, };
}




const TableCheck: unique symbol = Symbol("TableCheck");
class OuterError extends Error {
  constructor(message: string, public inner: Error) { super(message); }
}

function errorOnFirst<T>(message?: string): (source: Observable<T>) => Observable<T> {
  const error1 = new Error("Error on first emission operator")
  return (source: Observable<T>) => new Observable<T>(subscriber => {
    const error2 = new OuterError("Error on first emission subscriber", error1);
    const subscription = source.subscribe({
      next(value) { subscriber.error(new OuterError("Error on first emission" + message ? ": " + message : "", error2)); },
      error(err) { subscriber.error(err); },
      complete() { subscriber.complete(); }
    });
    return () => subscription.unsubscribe();
  });
}

function suspend(prom: Promise<any>) {
  // debugger;
  throw prom;
}

