import { BlockStack, IndexFilters, TabProps } from "@shopify/polaris";
import { ok, TableViewColumn } from "common";
import React, { useDeferredValue, useMemo, useReducer, useState } from "react";
import { useAsyncEffect } from "react-utils";
import { useColFilters } from "./useFilters";
import {  SelectTable2, useTableState } from "./SelectTable";
import { DataListColumn, DataListIdFieldColumn } from "../utils";
import { assignColumnOptions } from "./table-views";
import { CustomColumnDef } from "./CustomColumnState";
import { makeTableRowRedux } from "./TableRowRedux";

export interface TableViewColumnCustom<T, V> extends TableViewColumn<any, V> {
  key: string,
  calculate: (row: T) => V;
  link?: (row: T) => string | null | undefined;
  /** Call DataListColumn.getFieldType */
  schemaType?: ReturnType<typeof DataListColumn.getFieldType>;
}

export function useTableCols<T>(
  /** This gets memoed */
  colsOptionsMemo: () => readonly TableViewColumnCustom<T, any>[],
  colsDeps: any[],

  // /** This is only used for type, the value is not used */
  // rowType: T[],
  idKey: keyof T,
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const colsOptions = useMemo(colsOptionsMemo, colsDeps);

  ok(colsOptions.length > 0, "at least one header is required");

  const cols = useMemo(() => colsOptions.map((c) => {
    const col = new DataListColumn(c.key, c.sort ?? 0, c.schemaType ?? CustomColumnDef.getColumnClass(c.key, c.filterType ?? "none"));
    assignColumnOptions(col, c);
    return col;
  }), [colsOptions]);

  return { cols, ...useTableState(cols, idKey as string) };

}

export interface CustomTableData<T> {
  cols: DataListColumn[];
  idcol: DataListIdFieldColumn;
  rows: T[];
  loading: boolean;
}

export function useTableData<T>(
  /** This gets memoed */
  colsOptionsMemo: () => readonly TableViewColumnCustom<T, any>[],
  /** Col memo deps */
  colsDeps: any[],
  /** Mount function which returns the rows */
  mount: () => Promise<T[]>,
  /** Watch deps which cause the mount function to be called again to fetch fresh data */
  mountDeps: any[],
  idKey: keyof T,
) {

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { cols, idcol, rows, setValue } = useTableCols(colsOptionsMemo, colsDeps, idKey);

  const { loading } = useAsyncEffect(async () => {
    setValue({ action: "reset", newValue: await mount() });
  }, undefined, undefined, mountDeps);

  return { cols, idcol, rows, loading };

}

export function CustomTable<T>({

  curTab = 0,
  setTab,
  showFilters = false,
  onSelectRow,
  tabLabels,
  emptyMarkup,
  loadingMarkup,
  resourceName,
  tableData,
  pagination
}: {
  tableData: ReturnType<typeof useTableData>,
  curTab?: number;
  setTab?: React.Dispatch<React.SetStateAction<number>>;
  onSelectRow?: (row: string) => Promise<void>;
  showFilters?: boolean;
  tabLabels?: readonly string[];
  loadingMarkup?: () => JSX.Element;
  emptyMarkup?: () => JSX.Element;
  resourceName?: { singular: string, plural: string };
  pagination?: true | undefined;
}) {


  const { cols, idcol, rows, loading } = tableData;

  const { curAppliedFilters, curMode, setMode, filters, onClearAll } = useColFilters(cols);

  const [curQuery, setQuery] = useState("");
  const query = useDeferredValue(curQuery);
  const emptyState = loading ? loadingMarkup?.() : emptyMarkup?.();


  const tabs = useMemo((): TabProps[] => {
    if (!tabLabels) return [];
    return tabLabels.map(e => ({
      id: e,
      content: e,
      accessibilityLabel: e,
      panelID: e,
    }))
  }, [tabLabels]);

  return (
    <BlockStack gap="0">
      {showFilters && <IndexFilters
        cancelAction={{ onAction: onClearAll }}
        filters={filters}
        appliedFilters={curAppliedFilters}
        onClearAll={onClearAll}
        mode={curMode}
        setMode={setMode}
        onQueryClear={() => { }}
        queryValue={curQuery}
        onQueryChange={setQuery}
        hideQueryField={false}
        hideFilters={false}
        tabs={tabs}
        selected={curTab}
        onSelect={setTab ?? (() => { })}
        canCreateNewView={false}
        disableStickyMode
        disableKeyboardShortcuts />}
      {<SelectTable2
        loading={loading}
        query={query}
        cols={cols}
        rows={rows}
        idcol={idcol}
        emptyState={emptyState}
        onClickRow={onSelectRow}
        curAppliedFilters={curAppliedFilters}
        resourceName={resourceName}
        pagination={pagination}
      />}
    </BlockStack>
  );

}

