import { PropsWithChildren, useCallback, useMemo } from "react";

import { NgZone } from "@angular/core";
import { Router } from "@angular/router";
import {
  BlockStack,
  Box,
  Card,
  InlineGrid,
  InlineStack,
  Page,
  Text
} from '@shopify/polaris';
import { ColorBackgroundAlias } from "@shopify/polaris-tokens";
import { MenuGroupDescriptor } from "@shopify/polaris/types";
import { Customer, CustomerLedger, Prisma, Rental, SPPF, SPPI, dateCubes, ok } from "common";
import { DataService } from "data-service";
import { format } from "date-fns";
import { plural, singular } from "pluralize";
import { Chart } from 'primereact/chart';
import { Route } from "react-router";
import { StatusBadge, Tone, useAngular, useAsyncEffect } from "react-utils";
import { TableListSimple, useTableListInner } from "../tables/TableListInner";
import { customerTableViews, TableView, TableViewClass } from "../tables/table-views";
import { useBranchSelector } from '../utils';
import { RevenueChartsDataSource } from "../utils/useRevenueChartsDataSource";
import { DateTime } from "luxon";
// import { useSingleDataQuery } from "common";
/** calls singular for the given text if count is 1 */
function singularCount(text: string, count: number) {
  return count === 1 ? singular(text) : text;
}
/** calls plural for the given text if count is not 1 */
function pluralCount(text: string, count: number) {
  return count !== 1 ? plural(text) : text;
}

const NewFlag = (date: string) => {
  const diff = DateTime.fromFormat(date, "yyyy-MM-dd").diffNow("days");
  return diff.days > -7 && diff.days <= 0 ? "(NEW)" : "";
}

export function CardChart({ type, props, title, button, onClick }: {
  type: string; props: any; title: string; button: string; onClick?: () => void
}) {
  return (
    <Card>
      <InlineStack align="space-between">
        <Text as="h2" variant="headingMd">{title}</Text>
        {/* <Button onClick={onClick}>{button}</Button> */}
      </InlineStack>
      <Chart type={type} height="200px" {...props} />
    </Card>
  );
}

export default function RouteDashboard() {
  return (
    <Route path="Branch/dashboard">
      <Route path="" Component={Dashboard} />
      <Route path=":curBranch" Component={Dashboard} />
    </Route>
  );
}

function StatusArray({ children }: { children: [boolean, Tone, string, Tone, string][]; }) {
  return <>{children.map(([value, trueTone, trueLabel, falseTone, falseLabel]) =>
    <StatusBadge {...{ value, trueTone, trueLabel, falseTone, falseLabel }} />
  )}</>;
}

export function Dashboard() {
  const { get } = useAngular();
  const data = get(DataService);
  const router = get(Router);
  const zone = get(NgZone);
  const actionGroups: MenuGroupDescriptor[] = [];
  const { branchSelectorActionGroup, curBranch, curBranchTitle } = useBranchSelector();

  if (branchSelectorActionGroup && data.status.branchType === "CENTRAL")
    actionGroups.push(branchSelectorActionGroup);

  const { result } = useAsyncEffect(async () => await Promise.all([
    RevenueChartsDataSource(data, curBranch, true),
    RevenueChartsDataSource(data, curBranch, false),
    getStripeStatus(data, curBranch),
  ]), async () => { }, async () => { }, [curBranch]);

  const [curRevenueChartData, curUnpaidChartData, stripeStatus] = result ?? [];

  const rentalStatusWatchView = useMemo(() => makeRentalStatusWatchView(curBranch), [curBranch]);

  const customerPaymentsView = useMemo(() => makeCustomerPaymentsView(curBranch), [curBranch]);

  const customerPastDueState = makeCustomersPastDueState(curBranch);
  // const customerManpayState = makeCustomersPastDueState(curBranch, "mancust");
  // const customerManchargeState = makeCustomersPastDueState(curBranch, "mancharge");

  const hasinfo = !!stripeStatus?.payouts_enabled;
  const requirements = stripeStatus?.requirements;
  const hasFutureReqs = !!requirements?.eventually_due?.length;
  const hasCurrentReqs = !!requirements?.currently_due?.length;
  const currentdue = requirements?.current_deadline ? "by " + new Date(requirements.current_deadline).toLocaleString() : "soon";

  return <Page
    title={curBranchTitle || "No Branch Selected"}
    actionGroups={actionGroups}
    fullWidth
  >
    {/* {loading ? loadingMarkup() : <p>No branch selected</p>} */}
    {(curBranch || data.userRole === "web_admin") ? <BlockStack gap="500">
      {stripeStatus && <Card>
        <BlockStack gap="400">
          <Text as="span" variant="headingSm">Stripe Account Status</Text>
          <StatusArray>
            {[!!hasinfo, "success", "Payouts enabled", "attention", "Payouts disabled"]}
            {[
              hasFutureReqs,
              hasCurrentReqs ? "critical" : "warning",
              hasCurrentReqs ? "Stripe needs more info " + currentdue : "Stripe needs more info eventually",
              "success",
              "Sripe info up to date"
            ]}
          </StatusArray>
        </BlockStack>
      </Card>}
      {(curBranch && curRevenueChartData && curUnpaidChartData ? <InlineGrid columns={2} gap="500">
        <CardChart type="bar" props={curRevenueChartData} title="Revenue" button="Ledger" onClick={() => {
          router.navigateByUrl(`/Branch/ledger-valid/${curBranch}`)
        }} />
        <CardChart type="pie" props={curUnpaidChartData} title="Awaiting Payment" button="Pending" onClick={() => {
          router.navigateByUrl(`/Branch/ledger-pending/${curBranch}`)
        }} />
      </InlineGrid> : null)}
      <CardSection title="Rentals Moving In or Moving Out">
        <TableListSimple table="Rental" view={rentalStatusWatchView} emptyState={curBranch ? <span>None</span> : <span>No branch selected</span>} />
      </CardSection>
      {/* <CardSection title="Manual Payments Required">
        {customerManchargeState.useMarkup()}
      </CardSection> */}
      <CardSection title="Customers requiring manual payments">
        <Box paddingInline="300" paddingBlockEnd="300">
          <Text as="p" variant="headingMd">{NewFlag("2024-12-26")} Autopay Blocked for past due customers</Text>
          <Text as="p" variant="bodyMd">
            If a customer is past due, autopay will skip that customer.
            You or the customer will need to manually pay the balance.
            This is calculated using all approved and cleared payments, and all invoice lines dated before today.
          </Text>
        </Box>
        {customerPastDueState.useMarkup()}
      </CardSection>
      {/* <CardSection title="Upcoming due customers - Autopay disabled">
        {customerManpayState.useMarkup()}
      </CardSection> */}
      <CardSection title="Customer Payments">
        <TableListSimple table="CustomerLedger" view={customerPaymentsView} emptyState={<span>None yet</span>} />
      </CardSection>
    </BlockStack> : <Card>No branch selected</Card>}
  </Page>;

}

function makeCustomersPastDueState(curBranch: string) {
  const state = useTableListInner({
    table: "Customer",
    views: useMemo((): TableView[] => [
      TableViewClass.makeClientView("Customer", {
        key: "attention",
        title: "Attention",
        helptext: "",
        getCount: true,
        AND: [{
          IS_TESTING: false,
          // if a customer owes money, it follow them wherever the unit goes
          AllRentals: { some: { unit: curBranch ? { currentBranchID: curBranch } : {}, } },
        }] satisfies Prisma.CustomerWhereInput[],
        list: (x => [
          { key: x.billing.Name.__, link: (x: any) => `/Customer/edit/${x.id}` },
          x.billing.Address.description.__,
          x.billing.Phone.__,
          // x.PaymentInfoValid.__,
          { key: x.AutoPay.__, title: "Autopay Enabled" },
          { key: x.id.__, hidden: true, filterType: "none" },
        ] as const),
        sort: x => ["-pastDueSince" as SPPI],
      })], [curBranch]),
    hideFilters: true,
    extraAppliedFilters: [{
      key: "hasBalance", label: "Has Balance", onRemove: () => { },
      clientCheck: (row) => {
        ok(state.customs);
        const customer = state.customs.customerAutopayDataLookup.col.get(row);
        if (!customer) return true;
        return customer.hasPastDue
          || !customer.AutoPay && customer.hasBalance
          || customer.AutoPay && customer.LedgerLines.some(e =>
            !e.details.thisLinePaid && (!e.details.isForActiveRental || !e.canLineEverBeEligible)
          );
      },
    }],
  });
  return state;
}

const CardSection = ({ title, children }: PropsWithChildren<{ title: string }>) => (
  <Card padding="0">
    <Box padding="300">
      <Text as="span" variant="headingSm">{title}</Text>
    </Box>
    {children}
  </Card>
);


async function getStripeStatus(data: DataService, curBranch: string | undefined) {
  if (!curBranch) return;
  return await data.server.serverStripeAccountGetStatus({ PaymentLedger: "Branch", hostID: curBranch });
}

function makeRentalStatusWatchView(curBranch: string) {
  return ({
    key: "attention",
    title: "Attention",
    helptext: "",
    getCount: true,
    AND: [
      curBranch ? { unit: { currentBranchID: curBranch } } : {},
      { customer: { IS_TESTING: false } },
      { RentalStatus: { "in": ["Reserved", "Scheduled", "Moving_Out", "Completed"] } },
    ],
    list: (x => [
      x.customer.billing.Name.__,
      x.customer.Email.__,
      x.unit.Name.__,
      x.RentalStatus.__,
      x.StartDate.__,
      x.EndDate.__,
    ]) as SPPF<Rental>,
  } as TableView<any[], "Rental">);
}

function makeCustomerLatePaymentView(curBranch: string) {
  return ({
    key: "attention",
    title: "Attention",
    helptext: "",
    getCount: true,
    AND: [{
      IS_TESTING: false,
      LedgerLines: {
        some: {
          line: {
            VoidSince: null,
            Date: { lte: format(Date.now(), dateCubes) },
            invoiceLine: {
              paidOn: null,
              item: { ItemName: "Unit Rental" },
              rental: {
                unit: curBranch ? { currentBranchID: curBranch } : {},
                RentalStatus: { notIn: ["Archived", "Reserved"] },
              }
            }
          }
        }
      }
    }] satisfies Prisma.CustomerWhereInput[],
    list: (x => [
      { key: x.billing.Name.__, link: (x: any) => `/Customer/edit/${x.id}` },
      x.billing.Address.description.__,
      x.PaymentInfoValid.__,
      { key: x.id.__, hidden: true, filterType: "none" },
    ]),
    sort: x => ["nextAutopayDate" as SPPI],

  } as TableView<any[], "Customer">)
}

function makeCustomerManpayView(curBranch: string) {
  return ({
    key: "attention",
    title: "Attention",
    helptext: "",
    getCount: true,
    AND: [{
      IS_TESTING: false,
      AutoPay: false,
      AllRentals: { some: { unit: curBranch ? { currentBranchID: curBranch } : {}, } },
      LedgerLines: { some: { line: { VoidSince: null, Date: { lte: format(Date.now(), dateCubes) }, invoiceLine: { paidOn: null } } } }
    }] satisfies Prisma.CustomerWhereInput[],
    list: (x => [
      { key: x.billing.Name.__, link: (x: any) => `/Customer/edit/${x.id}` },
      x.billing.Address.description.__,
      x.PaymentInfoValid.__,
      { key: x.id.__, hidden: true, filterType: "none" },
    ]),
    sort: x => ["nextAutopayDate" as SPPI],

  } as TableView<any[], "Customer">)
}

function makeCustomerPaymentsView(curBranch: string) {
  return ({
    key: "attention",
    title: "Attention",
    helptext: "",
    getCount: true,
    AND: [{
      customer: curBranch ? { AllRentals: { some: { activeUnit: { currentBranchID: curBranch } } } } : undefined,
      line: { VoidSince: null, IS_TESTING: false, paymentLine: { is: {} } }
    }] satisfies Prisma.CustomerLedgerWhereInput[],
    list: x => [
      x.line.Date.__,
      x.line.customerLedgerLine.customer.billing.Name.__,
      x.Amount.__,
      x.line.paymentLine.PaymentStatus.__,
      { key: x.line.paymentLine.id.__, hidden: true, filterType: "none" },
    ],
    sort: x => [
      `-${x.line.Date.__}` as SPPI,
    ],
  } as TableView<any[], "CustomerLedger">)
}

function CardBlurb({ title, text: helptext, background, link }: { title: string, link?: string, text?: string, dir?: "up" | "down", background?: ColorBackgroundAlias }) {
  // <Icon source={ArrowUpMinor} color="primary" />
  const { get } = useAngular();
  const router = get(Router);
  const onClick = useCallback(() => link && router.navigateByUrl(link), [router, link]);
  return (
    <Card background={background} >
      <div className="flex flex-column align-items-center select-none cursor-pointer" style={{
        color: "var(--color-text-brand-on-bg-fill)",
      }} onClick={onClick}>
        <span style={{ fontSize: "1.5rem", }}>
          <span style={{
            display: "inline-block",
            verticalAlign: "bottom",
          }}></span>
          <span>{title}</span>

        </span>
        <p>{helptext}</p>
      </div>
    </Card>
  );
}
